import Vue from 'vue'

import Home from '../pages/Home.vue'
import Mittagskinder from '../pages/Mittagskinder.vue'
import Catering from '../pages/Catering.vue'
import Landgasthof from '../pages/Landgasthof.vue'
import Partnerfirmen from '../pages/Partnerfirmen.vue'
// import UeberUns from '../pages/UeberUns.vue'
// import Feiern from '../pages/Feiern.vue'
// import CateringPartyservice from '../pages/CateringPartyservice.vue'
// import Anfahrt from '../pages/Anfahrt.vue'
import Impressum from '../pages/Impressum.vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  linkExactActiveClass: 'active',
  routes: [
    { path: '/', component: Home, name: 'Willkommen' },
    { path: '/mittagskinder', component: Mittagskinder, name: 'Michls Mittagskinder'},
    { path: '/catering', component: Catering, name: 'Catering' },
    { path: '/landgasthof', component: Landgasthof, name: 'Landgasthof' },
    { path: '/partnerfirmen', component: Partnerfirmen, name: 'Partnerfirmen' },
    // { path: '/landgasthof', component: Landgasthof, name: 'Landgasthof' },
    // { path: '/ueber-uns', component: UeberUns, name: 'Über uns' },
    // { path: '/feiern', component: Feiern, name: 'Feiern' },
    // { path: '/anfahrt', component: Anfahrt, name: 'Anfahrt' },
    { path: '/impressum', component: Impressum, name: 'Impressum' },
  ]
})

export default router
<template>
  <div id="app">    
    <Header/>

    <div id="page-content">
        <headline-transition></headline-transition>
        <!-- <img alt="Michls Landgasthof Winkerling" src="assets/michl+kinder.jpg" class="img-thumbnail"> -->

        <div id="content-wrapper">
          <b-container fluid>
            <transition :name="transitionName">
              <router-view></router-view>
            </transition>
          </b-container>

          <!-- <div id="wrapper-footer"></div> -->
        </div>
    </div>
  </div>
</template>

<script>
// import Footer from './components/layout/Footer';
import Header from './components/layout/Header';
import {} from './components/transitions/HeadlineTransition';
import HeadlineTransition from './components/transitions/HeadlineTransition.vue';

export default {
  name: 'App',
  components: {
    // Footer,
    Header,
    HeadlineTransition
  },
  data() {
    return {
        transitionName: 'fade',
        pages: []
    };
  },
  watch: {
    $route (to, from) {
      var toIndex = null;
      var fromIndex = null;

      this.$router.options.routes.forEach(function (routeOption, optionIndex) {
        if (routeOption.path === to.path) {
          toIndex = optionIndex;
        }

        if (routeOption.path === from.path) {
          fromIndex = optionIndex;
        }
      });

      // TODO 
      var goDirection = fromIndex < toIndex ? 'slide-right' : 'slide-left';
      console.log('CHANGE ROUTE - ' + goDirection);
      // this.transitionName = goDirection;
    }
  },
  computed: {
    currentRoute() {
      return this.$route;
    },
    currentPageTitle() {
      return this.pageTitle;
    }
  }
}
</script>

<style>
/* TODO import font separately */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500&family=Raleway:wght@400&family=Source+Serif+Pro:wght@200;300;400,500&display=swap');*/

/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/raleway-v19-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/raleway-v19-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

#app {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>

<template>
  <div class="single-page-content text-center">
      <p class="mb-2">
          <a href="https://www.instagram.com/zauberhaft_marina/" target="_blank">
              <b-icon-instagram></b-icon-instagram>
              Mode Zauberhaft (<strong>@zauberhaft_marina</strong>)
          </a>
      </p>

      <p class="mb-2">
          <a href="https://www.facebook.com/people/Die-Blumenfee-Floristik-mehr/100063698675046/" target="_blank">
              <b-icon-facebook></b-icon-facebook>
              Blumenfee Teublitz
          </a>
      </p>
  </div>
</template>

<script>

export default {
  name: 'Partnerfirmen',
  data() {
    return {
      headline: 'Partnerfirmen'
    }
  },
  created() {
    this.$parent.$emit('onPageCreated', this);
  },
  mounted() {
    this.$parent.$emit('onPageMounted', this);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

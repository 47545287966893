<template>
  <div class="single-page-content">
    <h3 class="mt-2">
      Hochzeits - All Inclusive - Angebot*
    </h3>
    <hr class="mb-4 mt-1 headline--divider">

    <div class="offer mb-4">
      <ul class="mb-5 offer--list">
        <li><b-icon-heart-fill variant="danger"></b-icon-heart-fill> Sektempfang</li>
        <li><b-icon-heart-fill variant="danger"></b-icon-heart-fill> nachmittags Kaffee</li>
        <li><b-icon-heart-fill variant="danger"></b-icon-heart-fill> 3 Gänge Abend-Menü (Umfangreiches Angebot nach Absprache)</li>
        <li><b-icon-heart-fill variant="danger"></b-icon-heart-fill> Mitternachtsbuffet</li>
        <li><b-icon-heart-fill variant="danger"></b-icon-heart-fill> Softgetränke, Biere & Wein</li>
      </ul>
    
      <div id="offer--heart">
        <div id="offer--heart-text">
            <div class="headline">ab 85€ pro Person</div>
            <div class="subline">von 14:00 - 01:00 Uhr</div>
        </div>
      </div>

      <p><em>*exklusive Brautentführung</em></p>
    </div>

    <h4 class="mb-4">Eine Atmosphäre zum Wohlfühlen!</h4> 
 
    <p>
      Unser Haus empfiehlt sich für Feierlichkeiten aller Art. Ob Hochzeiten,
      Geburtstage, Familienfeiern, Busausflüge oder auch alle anderen Anlässe,
      unsere Räumlichkeiten bieten die unterschiedlichsten Möglichkeiten.
    </p>

    <p>
      So bietet das Nebenzimmer Platz für ca. 30 Personen, der geöffnete Saal für
      bis zu 150. Wir gestalten alles nach Ihren ganz persönlichen Wünschen. Und
      wenn Sie einmal nicht weiter wissen, so steht Ihnen unser kompetentes
      Team sehr gerne zur Verfügung. Ob die Auswahl der Gerichte, Gestaltung
      des Buffets oder die Tischdekoration, wir sind gerne bereit Ihnen mit Rat und
      Tat zur Seite zu stehen. Ihr Wohlbefinden ist unser oberstes Anliegen!
    </p>

    <agile :navButtons="false" :autoplay="true" :autoplaySpeed="5000" :fade="true" :speed="1000">

      <div class="slide" v-bind:style="{ backgroundImage: 'url(' + require ('../assets/images/gasthof_saal-9.jpg') + ')' }"></div>
      <div class="slide" v-bind:style="{ backgroundImage: 'url(' + require ('../assets/images/gasthof_saal-1.jpg') + ')' }"></div>
      <div class="slide" v-bind:style="{ backgroundImage: 'url(' + require ('../assets/images/gasthof_stammtisch-1.jpg') + ')' }"></div>
      <div class="slide" v-bind:style="{ backgroundImage: 'url(' + require ('../assets/images/gasthof_saal-5.jpg') + ')' }"></div>
      <div class="slide" v-bind:style="{ backgroundImage: 'url(' + require ('../assets/images/gasthof_saal-3.jpg') + ')' }"></div>
      <div class="slide" v-bind:style="{ backgroundImage: 'url(' + require ('../assets/images/gasthof_saal-6.jpg') + ')' }"></div>
    </agile>     
  </div>
</template>

<script>
export default {
  name: 'Landgasthof',
  data() {
    return {
      headline: 'Landgasthof'
    }
  },
  created() {
    this.$parent.$emit('onPageCreated', this);
  },
  mounted() {
    this.$parent.$emit('onPageMounted', this);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <div class="single-page-content">

      <h4 class="mb-3">Sehr geehrte Gastgeber,</h4>

      <p class="mb-4">
        Hier können Sie einen Auszug aus unserem Catering-Angebot einsehen und herunterladen.<br/><br/>
      
        Sehen Sie unsere Speisenvorschläge als eine Möglichkeit von vielen. In einem persönlichen Gespräch gehen wir gerne auf Ihre individuellen Wünsche und Vorstellungen ein. Dabei profitieren Sie von unserer Erfahrung!<br/><br/>

        Egal ob Geburtstage, Hochzeit oder andere Festlichkeiten - mit uns wird Ihre Veranstaltung zu einem gelungenen Fest.<br/><br/>

        Gerne sind wir auch bereit Ihnen außer unseren feinen Speisen Servicepersonal, Getränke usw. zu liefern. Wir bieten Ihnen ein Komplettpaket von A bis Z!<br/>
      </p>


      <div class="card--group mb-4" v-for="(filesrc, index) in filesrcs" :key="index" xs12 md4>
        <a v-bind:href="filesrc.src.default" target="_blank">
          <b-card @click="downloadWithAxios(filesrc.src, filesrc.title)">
            <b-card-text>
              <b-icon-download></b-icon-download> {{ filesrc.title }}
            </b-card-text>
          </b-card>
        </a>
      </div>

      <agile :navButtons="false" :autoplay="true" :centerMode="true" :autoplaySpeed="5000" :fade="true" :speed="1000">
        <div class="slide" v-bind:style="{ backgroundImage: 'url(' + require ('../assets/images/landgasthof-buffet.jpg') + ')' }">
        </div>
        <div class="slide" v-bind:style="{ backgroundImage: 'url(' + require ('../assets/images/gasthof_kueche-2.jpg') + ')' }">
        </div>
        <div class="slide" v-bind:style="{ backgroundImage: 'url(' + require ('../assets/images/gasthof_kueche-3.jpg') + ')' }">
        </div>
      </agile> 
  </div>
</template>

<script>
// import pdf from 'vue-pdf';
import axios from 'axios'

export default {
  name: 'Catering',
  components: {
    // pdf
  },
  data() {
    return {
      headline: 'Catering',
      filesrcs: [
        {
          title: 'Landgasthof-Catering.pdf',
          src: require('../assets/files/Landgasthof-Catering.pdf'),
        },
      ],
    }
  },
  created() {
    this.$parent.$emit('onPageCreated', this);
  },
  mounted() {
    this.$parent.$emit('onPageMounted', this);
  },
  methods: {
    forceFileDownload(response, title) {
      console.log(title)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    downloadWithAxios(url, title) {
      console.log('URL', url, url.default);

      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
      })
        .then((response) => {
          this.forceFileDownload(response, title)
        })
        .catch(() => console.log('error occured'))
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

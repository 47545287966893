<template>
  <div class="single-page-content">
    <!-- <agile :navButtons="false" :autoplay="true" :autoplaySpeed="7000" :fade="true" :speed="1000" class="mb-3">
        <div class="slide">
            <img src="https://placekitten.com/g/1200/500">
        </div>
        
        <div class="slide">
            <img src="http://placekitten.com/g/1250/550">
        </div>
    </agile>       -->

      <p>
        <img right src="../assets/logo-mittagskinder.jpg" fluid alt="Michls Mittagskinder" class="mb-3 img-thumbnail" style="max-height: 200px; float: right; margin-left: 1rem;">

        Wir sind ein EU-zugelassener und zertifizierter Großküchenbetreiber (<strong>DE-BY30624</strong>). 
      </p>

      <p>
        Um die Zulassungen und Zertifizierungen zu erhalten, muss ein hohes Maß an Hygiene und Qualitätsstandards erfüllt werden.
        Hierzu haben wir unsere Küche in Winkerling zu einer der modernsten und größten Küchen im Landkreis Schwandorf ausgebaut.
      </p>

      <p>
        Seit dem Jahr 2000 sind wir erfolgreich im Partyservice und seit 2011 im Bereich <strong>Kindergarten- und Schulverpflegung</strong> mit viel Engagement und Leidenschaft tätig.
        Wir betreuten über 60 Kinderkrippen, -Gärten, -Horte und Schulen in der Region Schwandorf/Amberg/Regensburg, die unserem Frischekonzept (<strong>frisch - regional - saisonal</strong>) bereits seit vielen Jahren ihr Vertrauen schenken.
      </p>

      <blockquote class="mt-5 pt-4 mb-5 pb-4 blockquote">
        <span class="blockquote--before"></span>
        Wir sind stets bestrebt, Bio- sowie Vollkornprodukte aus fairem Handel für unsere Speisen zu verarbeiten - unser Frischekonzept für Ihr Vertrauen!
        <span class="blockquote--after"></span>
      </blockquote>

      <p>
        Mit dieser Philosophie bieten wir abwechslungsreiche und altersgerechte Speisen an, die lecker schmecken.
        Denn Lust an gutem, gesunden Essen beginnt bereits im Krippen- und Kindergartenalter. Hierzu gibt es öffentliche Studien, die belegen, wie frisch zubereitetes Essen Kinder bereits im kleinsten Alter positiv beeinflussen kann.
        Unser Ziel ist es, den uns von Ihnen anvertrauten Kindern und Jugendlichen eine ausgewogene Ernährung mit frischer, gesunder und vitaminreicher Kost anzubieten - basierend auf aktuellen ernährungswissenschaftlichen Erkenntnissen des Bundesministeriums für Ernährung.
        Unsere Speisepläne orientieren sich an den vorgegebenen Qualitätsstandards für Verpflegung in Tageseinrichtungen für Kinder nach Vorgabe der Deutschen Gesellschaft für Ernährung e.V.
      </p>

      <p>
        Sollten wir Ihr Interesse geweckt haben, können Sie uns <strong>jederzeit telefonisch oder per Mail</strong> erreichen, um ein unverbindliches Angebot zu erhalten.
        Ein kostenloses Probeessen ist jederzeit möglich.
      </p>

      <h4 class="mt-5"><strong>Wir von Michls Mittagskinder gewährleisten</strong>:</h4>

      <b-list-group class="mt-3">
        <b-list-group-item>täglich frische Essenszubereitung</b-list-group-item>
        <b-list-group-item>geschultes Küchenpersonal</b-list-group-item>
        <b-list-group-item>abwechslungsreicher Speiseplan - orientiert an DGE e.V.</b-list-group-item>
        <b-list-group-item>höchster Anspruch an Qualität, Frische und Regionalität</b-list-group-item>
        <b-list-group-item>regelmäßige Verwendung von Bio- & Vollkornprodukten</b-list-group-item>
        <b-list-group-item>Produkte aus fairem Handel</b-list-group-item>
        <b-list-group-item>eigene Rezepturen, fettarme Zubereitung, ausschließlich Kochen mit hochwertigem Rapsöl</b-list-group-item>
        <b-list-group-item>Tägliches Alternativangebot für Schweinefleischfreies oder vegetarisches Essen buchbar</b-list-group-item>
        <b-list-group-item>Berücksichtigung von Lebensmittelunverträglichkeiten nach Absprache</b-list-group-item>
        <b-list-group-item>Tiefkühl-Kost nur in Ausnahmefällen - z.B. Fisch oder nicht-erntefrisches Gemüse</b-list-group-item>
        <b-list-group-item>Ausschließlich frische Zubereitung von Nachtischen wie Pudding, Joghurt oder Quark zur Vermeidung von Plastikmüll</b-list-group-item>
        <b-list-group-item>Keine Verwendung von Alkohol</b-list-group-item>
        <b-list-group-item>Hoher Anspruch auf hohen Genusswert (sensorische Qualität) und Gesundheitswert (Ernährungsphysiologische Qualität)</b-list-group-item>
        <b-list-group-item>Aufbewahrung von Speiseproben für 2 Wochen für Kontrollzwecke</b-list-group-item>
        <b-list-group-item>Pünktliche Anlieferung von Montag bis Freitag</b-list-group-item>
        <b-list-group-item>Auslieferung in hygienisch einwandfreien Wärmebehältern & Thermoporten nach HACCP Standard</b-list-group-item>
      </b-list-group>

      <p class="mt-5">
        Wir setzen Wert auf gegenseitigen Informationsfluss und sind im ständigen Austausch über Wünsche, Ideen, Anregungen und Lieblingsgerichte unserer jungen Gäste.
        Auch können mehrmals jährlich Umfragebögen ausgefüllt werden, deren Resultate auch in unseren Speiseplan mit einfließen.
        
        Um unsere Arbeit den hohen Ansprüchen der heutigen Zeit gerecht werden zu lassen, besuchen wir kontinuierlich Workshops für gesundheitsförderliche Verpflegung in Einrichtungen mit Kindern, 
        z.B. beim Amt für Ernährung in Regensburg.
      </p>
  </div>
</template>

<script>
// import { VueAgile } from 'vue-agile'

export default {
  name: 'Michls Mittagskinder',
  components: {
    // agile: VueAgile 
  },
  data() {
    return {
      headline: 'Michls Mittagskinder'
    }
  },
  created() {
    this.$parent.$emit('onPageCreated', this);
  },
  mounted() {
    this.$parent.$emit('onPageMounted', this);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

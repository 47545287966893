import Vue from 'vue'
import VueAgile from 'vue-agile'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import './scss/app.scss'

import router from './router'
import App from './App.vue'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueAgile);

new Vue({
   router,
   render: h => h(App)
}).$mount('#app')

<template>
  <header>
    <b-navbar toggleable="lg" type="dark">
      <b-navbar-brand href="/">
        Michls Landgasthof
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Menu -->
        <b-navbar-nav class="navbar-primary">
            <b-nav-item v-for="route in routes" :key="route.path" :to="route.path" v-bind:class="{ 'active' : currentRoute.path === route.path }">
              {{route.name}}
            </b-nav-item>

            <hr class="navbar--indicator"/>
        </b-navbar-nav>

        <!-- Contact -->
        <navbar-contact></navbar-contact>

        <!-- Copyright -->
         <b-navbar-nav class="navbar-secondary">
            <b-nav-text>&#169;{{ new Date().getFullYear() }} Michael Hofmann</b-nav-text>
          </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
import NavbarContact from '../content/NavbarContact.vue';
export default {
  components: { NavbarContact },
  name: 'Header',
  data() {
    return {
      routes: this.$router.options.routes
    };
  },
  computed: {
    currentRoute() {
      return this.$route;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <div class="single-page-content">
    <b-row class="mb-3">
      <b-col>
        <!-- <h3 class="text-center">Wir bei Michls Landgasthof möchten Sie recht herzlich auf unserer Website begrüßen!</h3> -->
      
        <h4 class="mt-2">
          Bei uns können Sie die Korken knallen lassen!
        </h4>
        <hr class="mb-4 mt-1 headline--divider">
        <p>
          Egal ob Geburtstag, Familien- oder Firmenfeier, Hochzeiten und viele weitere Events!<br/>
          Für jeden Anlass bieten wir Ihnen die perfekte Lokalität. Entdecken Sie auch unser großzügiges Catering-Angebot!<br><br>
          Mit unserer jahrelangen Erfahrung stehen wir Ihnen mit kompetenter Beratung und erstklassigem Kunden-Service zur Seite.<br><br/>
          Wir machen Ihre Feierlichkeit zu einem unvergesslichen Erlebnis!<br><br>
        </p>

        <blockquote class="mt-3 pt-4 mb-5 pb-4 blockquote">
          <img src="../assets/michls-landgasthof-team.jpg" fluid alt="Michls Landgasthof - Familie Hofmann" class="mb-3 img-thumbnail" style="max-height: 350px;">
          <span class="blockquote--before"></span>
          <p style="font-size: 20px">Michl mit seinen Kindern!</p>
          <span class="blockquote--after"></span>
        </blockquote>


        <h4 class="mt-5">
          Entdecken Sie unsere Angebote
        </h4>

        <hr class="mb-4 mt-1 headline--divider">
      </b-col>
    </b-row>

    <b-row class="teaser--container">
      <b-col class="col-12 col-xl-4">
        <router-link to="/mittagskinder" class="page--teaser is--dark" v-bind:style="{ backgroundImage: 'url(' + require ('../assets/logo-mittagskinder.jpg') + ')' }">
          <span class="teaser--overlay">
            <h5 class="teaser--headline">Michls Mittagskinder</h5>
          </span>
        </router-link>
      </b-col>
      <b-col class="col-12 col-xl-4">
        <router-link to="/catering" class="page--teaser is--cover" v-bind:style="{ backgroundImage: 'url(' + require ('../assets/images/gasthof_kueche-2.jpg') + ')' }">
          <span class="teaser--overlay">
            <h5 class="teaser--headline">Catering</h5>
          </span>
        </router-link>
      </b-col>
      <b-col class="col-12 col-xl-4">
        <router-link to="/landgasthof" class="page--teaser is--cover" v-bind:style="{ backgroundImage: 'url(' + require ('../assets/images/gasthof-hochzeit.jpg') + ')' }">
          <span class="teaser--overlay">
            <h5 class="teaser--headline">Landgasthof</h5>
          </span>
        </router-link>
      </b-col>
    </b-row>

    <!-- <b-row class="mb-5 text-center">
      <b-col>
        <p>
          <strong>Wir freuen uns darauf, von Ihnen zu hören!</strong>
          <br/>
          <br/>
          Ihre Familie Hofmann
        </p>
        <img alt="Michael Hofmann und Kinder" src="../assets/michl+kinder.jpg" class="img-thumbnail" style="max-height: 400px">
      </b-col>
    </b-row> -->

    <!-- <b-col>
        
      </b-col> -->
  </div>
</template>

<script>
export default {
  name: 'Willkommen',
  data() {
    return {
      headline: 'Herzlich Willkommen bei Michls Landgasthof'
    }
  },
  created() {
    this.$parent.$emit('onPageCreated', this);
  },
  mounted() {
    this.$parent.$emit('onPageMounted', this);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
